import React from 'react';
import './Form.css';

function highlightField(event) {
    event.target.select();
}

function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', 'currency': 'USD'}).format(value || 0);
}

export default function(props) {
    const loan = props.loan;
    const ops = props.loanMethods;
    return (
        <form form="ngForm" noValidate>
            <div className="input-group">
                <label htmlFor="">Purchase Price</label>
                <input
                    type="text"
                    name="purchasePrice"
                    value={loan.purchasePrice}
                    onChange={ops.updatePurchasePrice}
                    onClick={highlightField}
                />
            </div>

            <div className="input-group">
                <label htmlFor="">Down Payment</label>
                <input
                    type="text"
                    name="downPaymentAmount"
                    value={loan.downPaymentAmount}
                    onChange={ops.updateDownPaymentAmount}
                />
                <input
                    type="text"
                    name="downPaymentPercent"
                    value={loan.downPaymentPercent}
                    onChange={ops.updateDownPaymentPercent}
                />
            </div>

            <div className="input-group">
                <label htmlFor="">Loan Amount</label>
                <input
                    type="text"
                    name="loanAmount"
                    value={loan.loanAmount}
                    onChange={ops.updateLoanAmount}
                />
            </div>

            <div className="input-group">
                <label htmlFor="">Interest</label>
                <input
                    type="text"
                    name="interest"
                    value={loan.interest}
                    onChange={ops.updateInterest}
                />
            </div>

            <div className="input-group">
                <label htmlFor="">Loan Term</label>
                <input
                    type="text"
                    name="loanTermYears"
                    value={loan.loanTermYears}
                    onChange={ops.updateLoanTermYears}
                />
                <input
                    type="text"
                    name="loanTermMonths"
                    value={loan.loanTermMonths}
                    onChange={ops.updateLoanTermMonths}
                />
            </div>

            <div className="group">
                <label htmlFor="">Monthly Payment</label>
                {formatCurrency(loan.monthlyPayment)}
            </div>
            <div className="group">
                <label htmlFor="">Total Payment</label>
                {formatCurrency(loan.totalPayment)}
            </div>
            <div className="group">
                <label htmlFor="">Total Interest</label>
                {formatCurrency(loan.totalInterest)}
            </div>
        </form>
    );
};
