import React, { Component } from 'react';
import Form from './Form.js';

import loanCalc from '../models/Loan.js';

class LoanCalculator extends Component {
    constructor(props) {
        super(props);
        let loan = {
            purchasePrice: 200000,
            downPaymentAmount: 50000,
            downPaymentPercent: 0.25,
            loanAmount: 150000,
            interest: 4.5,
            loanTermMonths: 360,
            loanTermYears: 30,
            monthlyPayment: 0,
        };
        loan.monthlyPayment = loanCalc.calculateMonthlyPayment(loan);
        loan.totalPayment = loanCalc.calculateTotalPayment(loan);
        loan.totalInterest = loanCalc.calculateTotalInterest(loan);
        this.state = {
            loan,
        };

        this.loanMethods = {};
        Object.keys(loanCalc).forEach(method => {
            this[method] = event => {
                this.setState({
                    loan: loanCalc[method](this.state.loan, event.target.value)
                });
            };

            this.loanMethods[method] = this[method];
        });
    }

    render() {
        return (
            <div>
                <h1>loan-calc</h1>
                <Form loan={this.state.loan} loanMethods={this.loanMethods} />
            </div>
        );
    }
}

export default LoanCalculator;