const self = {

    updatePurchasePrice(loan, purchasePrice) {
        let downPaymentAmount = loan.downPaymentPercent * purchasePrice;
        let newLoan = {
            ...loan,
            downPaymentAmount,
            purchasePrice,
            loanAmount: purchasePrice - downPaymentAmount,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateDownPaymentAmount(loan, downPaymentAmount) {
        let newLoan = {
            ...loan,
            downPaymentAmount,
            downPaymentPercent: downPaymentAmount / loan.purchasePrice,
            loanAmount: loan.purchasePrice - downPaymentAmount,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateDownPaymentPercent(loan, downPaymentPercent) {
        let downPaymentAmount = loan.purchasePrice * downPaymentPercent;
        let newLoan = {
            ...loan,
            downPaymentPercent,
            downPaymentAmount,
            loanAmount: loan.purchasePrice - downPaymentAmount,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateLoanAmount(loan, loanAmount) {
        let newLoan = {
            ...loan,
            loanAmount,
            downPaymentAmount: loan.purchasePrice - loanAmount,
            downPaymentPercent: loanAmount / loan.purchasePrice,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateInterest(loan, interest) {
        let newLoan = {
            ...loan,
            interest,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateLoanTermYears(loan, loanTermYears) {
        let newLoan = {
            ...loan,
            loanTermYears,
            loanTermMonths: loanTermYears * 12,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    updateLoanTermMonths(loan, loanTermMonths) {
        let newLoan = {
            ...loan,
            loanTermYears: loanTermMonths / 12,
            loanTermMonths,
        };

        newLoan.monthlyPayment = self.calculateMonthlyPayment(newLoan);
        newLoan.totalPayment = self.calculateTotalPayment(newLoan);
        newLoan.totalInterest = self.calculateTotalInterest(newLoan);
        return newLoan;
    },

    calculateMonthlyPayment(loan) {
        let monthlyInterest = loan.interest / 100 / 12;
        let capRate = Math.pow(1 + monthlyInterest, loan.loanTermMonths);
        let monthlyPayment = (loan.loanAmount * monthlyInterest * capRate) / (capRate - 1);
        return monthlyPayment;
    },

    calculateTotalPayment(loan) {
        const { loanTermMonths, monthlyPayment } = loan;
        return loanTermMonths * monthlyPayment;
    },

    calculateTotalInterest(loan) {
        const { totalPayment, loanAmount } = loan;
        return totalPayment - loanAmount;
    },

}

export default self;
